<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Factors" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Title On Home *"
                  v-model="titleOnHome"
                  :error="!!errors.factor_title"
                  :error-messages="errors.factor_title"
                  @input="delete errors.factor_title"
                />
                <div style="margin-bottom: 15px;">
                  <span>Description On Home *</span>
                  <ckeditor v-model="descriptionOnHome" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.factor_description" style="color: red;">{{ errors.factor_description[0] }}</div>
                </div>
                <div style="margin-bottom: 15px;">
                  <span>Content On Home *</span>
                  <ckeditor v-model="contentOnHome" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.factor_content" style="color: red;">{{ errors.factor_content[0] }}</div>
                </div>
                <va-card class="mb-3" title="Image On Home">
                  <div v-if="imageOnHomeExisting">
                    <a style="display: block;" target="_blank" :href="imageOnHomeExisting"><img style="max-width: 400px;" :src="imageOnHomeExisting"></a>
                    <div><va-button small color="danger" @click="imageOnHomeExisting = deleteImage(imageOnHomeId, 'factor_image') ? '' : imageOnHomeExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.factor_image"
                    v-model="imageOnHome"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('factor_image', 'factor_image', imageOnHome[0])">Завантажити</va-button>
                  <div v-if="errors.factor_image" style="color: red;">{{ errors.factor_image[0] }}</div>
                </va-card>
                <va-toggle small label="Show On Home" v-model="showOnHome"></va-toggle>

                <va-input
                  label="Title On Responsibility"
                  v-model="titleOnResponsibility"
                  :error="!!errors.factor_title_responsibility"
                  :error-messages="errors.factor_title_responsibility"
                  @input="delete errors.factor_title_responsibility"
                />
                <div style="margin-bottom: 15px;">
                  <span>Description On Responsibility *</span>
                  <ckeditor v-model="descriptionOnResponsibility" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.factor_description_responsibility" style="color: red;">{{ errors.factor_description_responsibility[0] }}</div>
                </div>
                <div style="margin-bottom: 15px;">
                  <span>Content On Responsibility *</span>
                  <ckeditor v-model="contentOnResponsibility" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.factor_content_responsibility" style="color: red;">{{ errors.factor_content_responsibility[0] }}</div>
                </div>
                <va-card class="mb-3" title="Image On Responsibility">
                  <div v-if="imageOnResponsibilityExisting">
                    <a style="display: block;" target="_blank" :href="imageOnResponsibilityExisting"><img style="max-width: 400px;" :src="imageOnResponsibilityExisting"></a>
                    <div><va-button small color="danger" @click="imageOnResponsibilityExisting = deleteImage(imageOnResponsibilityId, 'factor_image_responsibility') ? '' : imageOnResponsibilityExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.factor_image_responsibility"
                    v-model="imageOnResponsibility"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('factor_image_responsibility', 'factor_image_responsibility', imageOnResponsibility[0])">Завантажити</va-button>
                  <div v-if="errors.factor_image_responsibility" style="color: red;">{{ errors.factor_image_responsibility[0] }}</div>
                </va-card>
                <va-toggle small label="Show Button On Responsibility" v-model="showButtonOnResponsibility"></va-toggle>
                <va-toggle small label="Show On Responsibility" v-model="showOnResponsibility"></va-toggle>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      titleOnHome: '',
      descriptionOnHome: '',
      contentOnHome: '',
      imageOnHome: [],
      imageOnHomeId: '',
      imageOnHomeExisting: '',
      showOnHome: false,
      titleOnResponsibility: '',
      descriptionOnResponsibility: '',
      contentOnResponsibility: '',
      imageOnResponsibility: [],
      imageOnResponsibilityId: '',
      imageOnResponsibilityExisting: '',
      showButtonOnResponsibility: false,
      showOnResponsibility: false,

      errors: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AGRO_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        factor_title: this.titleOnHome,
        factor_description: this.descriptionOnHome,
        factor_content: this.contentOnHome,
        factor_show: this.showOnHome ? 1 : 0,

        factor_title_responsibility: this.titleOnResponsibility,
        factor_description_responsibility: this.descriptionOnResponsibility,
        factor_content_responsibility: this.contentOnResponsibility,
        factor_image_responsibility: this.imageOnResponsibilityExisting,
        factor_button_responsibility: this.showButtonOnResponsibility ? 1 : 0,
        factor_show_responsibility: this.showOnResponsibility ? 1 : 0,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file, agg = 'factor') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('aggregate', agg)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/upload/6?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/6/delete-file/${id}?lang=${this.locale}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/6?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/validate/6?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/6?lang=${this.locale}`)
        .then(response => {
          this.titleOnHome = response.data.factor_title
          this.descriptionOnHome = response.data.factor_description ? response.data.factor_description : ''
          this.contentOnHome = response.data.factor_content ? response.data.factor_content : ''
          this.imageOnHomeExisting = response.data.factor_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.factor_image : null
          this.imageOnHomeId = response.data.factor_image_id
          this.showOnHome = !!response.data.factor_show

          this.titleOnResponsibility = response.data.factor_title_responsibility
          this.descriptionOnResponsibility = response.data.factor_description_responsibility ? response.data.factor_description_responsibility : ''
          this.contentOnResponsibility = response.data.factor_content_responsibility ? response.data.factor_content_responsibility : ''
          this.imageOnResponsibilityExisting = response.data.factor_image_responsibility ? process.env.VUE_APP_AGRO_ADMIN + response.data.factor_image_responsibility : null
          this.imageOnResponsibilityId = response.data.factor_image_responsibility_id
          this.showButtonOnResponsibility = !!response.data.factor_button_responsibility
          this.showOnResponsibility = !!response.data.factor_show_responsibility
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
